import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {useState} from "react";

const ContactSchema = Yup.object().shape({
    index: Yup.number(),
    ip_address: Yup.string().required('The field must be filled out'),
    port: Yup.string().required('The field must be filled out'),
    user: Yup.string().required('The field must be filled out'),
    password: Yup.string().required('The field must be filled out'),
});

const ProfilePage = () => {
    const [error, setError] = useState();
    const [save, setSave] = useState(false);

    const [devices, setDevices] = useState(
        JSON.parse(localStorage.getItem("devices")) || []
    );

    const userEmail = localStorage.getItem("userEmail") || {}
    const userPassword = localStorage.getItem("userPassword") || {}

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        const updatedDevices = [...devices];
        updatedDevices[values?.index] = values;

        localStorage.setItem("devices", JSON.stringify(updatedDevices));
        setDevices(updatedDevices);
        setSave(true);
        setSave(true);
        setSubmitting(false);
    };

    const handleDeleteDevice = (index) => {
        console.log(index)
        const updatedDevices = devices.filter((device) => device.index !== index);
        console.log(updatedDevices)
        localStorage.setItem("devices", JSON.stringify(updatedDevices));
        setDevices(updatedDevices);
    };

    const handleAddEmptyDevice = () => {
        if (devices.length < 4) {
            const emptyDevice = {
                index: "",
                ip_address: "",
                port: "",
                user: "",
                password: "",
            };
            setDevices([...devices, emptyDevice]);
        } else {
            setError("You are only allowed to add up to 4 devices.");
        }
    };

    return (
        <>
            <MetaTags>
                <title>Account | InfCam Cloud</title>
            </MetaTags>

            <section className="my-5 bg-white">
                <div className="container my-5">
                  <div className="row">
                      <div className="col-8">
                          <h1 className="fw-bold mb-3">Account</h1>
                      </div>
                      <div className="col-4 text-end">
                          <button className="btn btn-primary mb-3"
                                  onClick={handleAddEmptyDevice}>
                              Add device
                          </button>
                      </div>
                  </div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="card">
                                <div className="card-body">
                                    <img src="assets/images/devices.svg" alt=""/>
                                    <div className="mb-3">
                                        <label htmlFor="email">Email</label>
                                        <input id="email"
                                               className='form-control'
                                               value={userEmail}
                                               disabled
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password-b">Password</label>
                                        <input id="password-b"
                                               className='form-control'
                                               type="password"
                                               value={userPassword}
                                               disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-lg-8">
                            {error && <p className="mt-2 mb-0 text-danger mb-3">
                                {error}
                            </p>}

                            {save &&
                                <div
                                    className="alert alert-primary text-center"
                                    role="alert">
                                    Your details have been saved
                                </div>}

                            {devices.map((device, index) => (
                                <div className="mb-3" key={index}>
                                    <div className="card">
                                        <div className="card-body">
                                            <Formik
                                                initialValues={{
                                                    index: index,
                                                    ip_address: device?.ip_address || '',
                                                    port: device?.port || '',
                                                    user: device?.user || '',
                                                    password: device?.password || '',
                                                }}
                                                validationSchema={ContactSchema}
                                                onSubmit={handleSubmit}
                                                enableReinitialize={true}
                                            >
                                                {({isSubmitting, errors, touched}) => (
                                                    <Form>
                                                        <Field
                                                            type="hidden"
                                                            name="index"
                                                            id="index"
                                                            value={index}
                                                        />

                                                        <div className="row row-cols-2 g-3">
                                                            <div className="col">
                                                                <label htmlFor="ip_address">IP address</label>
                                                                <Field
                                                                    className='form-control'
                                                                    fullWidth
                                                                    type="text"
                                                                    name="ip_address"
                                                                    id="ip_address"
                                                                />
                                                                {errors.ip_address && touched.ip_address ? (
                                                                    <div
                                                                        className='error'>{errors.ip_address}</div>
                                                                ) : null}
                                                            </div>


                                                            <div className="col">
                                                                <label htmlFor="port">Port</label>
                                                                <Field
                                                                    className='form-control'
                                                                    fullWidth
                                                                    type="text"
                                                                    name="port"
                                                                    id="port"
                                                                />
                                                                {errors.port && touched.port ? (
                                                                    <div
                                                                        className='error'>{errors.port}</div>
                                                                ) : null}
                                                            </div>


                                                            <div className="col">
                                                                <label htmlFor="user">User</label>
                                                                <Field
                                                                    className='form-control'
                                                                    fullWidth
                                                                    type="text"
                                                                    name="user"
                                                                    id="user"
                                                                />
                                                                {errors.user && touched.user ? (
                                                                    <div className='error'>{errors.user}</div>
                                                                ) : null}
                                                            </div>
                                                            <div className="col">
                                                                <label htmlFor="password">Password</label>
                                                                <Field
                                                                    className='form-control'
                                                                    fullWidth
                                                                    type="password"
                                                                    name="password"
                                                                    id="password"
                                                                />
                                                                {errors.password && touched.password ? (
                                                                    <div
                                                                        className='error'>{errors.password}</div>
                                                                ) : null}
                                                            </div>

                                                            <div className="col">
                                                                <button className='btn btn-danger w-100'
                                                                        onClick={() => handleDeleteDevice(device?.index)}>
                                                                    Delete
                                                                </button>
                                                            </div>

                                                            <div className="col">
                                                                <button
                                                                    className="btn btn-primary w-100"
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {isSubmitting ? "..." : "Save"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ProfilePage;