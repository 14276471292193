import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {useState} from "react";

const LoginPage = () => {
    const [error, serError] = useState();

    const ContactSchema = Yup.object().shape({
        email: Yup.string().email('An invalid email address').required('The field must be filled out'),
        password: Yup.string().required('The field must be filled out'),
    });

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        if ((values?.email === 'demo@infcam.io' && values?.password === 'nMB75jDp59tC4') ||
            (values?.email === localStorage.getItem("userEmail") && values?.password === localStorage.getItem("password"))) {
            localStorage.setItem("userEmail", values?.email);
            window.location.href = '/account';
        } else {
            serError("Wrong credentials")
        }

        setSubmitting(false)
    };

    return (
        <>
            <MetaTags>
                <title>Authorization | InfCam Cloud</title>
            </MetaTags>
            <section className="my-5">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 text-center">
                            <img src="assets/images/login.svg" alt=""/>
                        </div>
                        <div className="col-12 col-lg-6">
                            <Formik
                                initialValues={{email: '', password: ''}}
                                validationSchema={ContactSchema}
                                onSubmit={handleSubmit}
                            >
                                {({isSubmitting, errors, touched}) => (
                                    <Form>
                                        <h2 className="mb-3 fw-bold">
                                            Authorization
                                        </h2>
                                        <div className="mb-3">
                                            <Field
                                                fullWidth
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Email"
                                                className='form-control py-3'
                                            />
                                            {errors.email && touched.email ? (
                                                <div className='error'>{errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Field
                                                fullWidth
                                                type="password"
                                                name="password"
                                                id="password"
                                                className='form-control py-3'
                                                placeholder="Password"
                                            />
                                            {errors.password && touched.password ? (
                                                <div className='error'>{errors.password}</div>
                                            ) : null}
                                        </div>

                                        {error && <span className="text-danger">
                                                    {error}
                                                </span>}

                                        <button
                                            className="btn btn-primary w-100 fw-bold py-3"
                                            type="submit"
                                            disabled={isSubmitting}
                                        >
                                            Submit
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LoginPage;