import MetaTags from "react-meta-tags";

const PrivacyPolicyPage = () => {
    return (
        <>
            <MetaTags>
                <title>Privacy policy | InfCam Cloud</title>
            </MetaTags>


            <div className="not-home">
                <div className="container mx-auto">
                    <h1>Data Confidentiality & Usage Policy</h1>
                    <p>This section aims to provide insights into our guidelines overseeing the acquisition, utilization, and exposure
                        of Personal Data during the utilization of our Service.</p>
                    <p>Your information will only be used or disclosed in accordance with the principles outlined in this Confidentiality Policy.</p>
                    <p>By engaging with the Service, you implicitly consent to the gathering and utilization of information as articulated in this
                        Confidentiality Policy. Unless otherwise defined in this Confidentiality Policy, terms hold the same significance
                        as detailed in our Terms of Service, available at <a
                            href="https://infcam.net/terms-of-use/">terms of use</a>.</p>

                    <h2>Data Collection And Utilization</h2>

                    <p>While accessing our Service, we may solicit specific personally identifiable
                        details to contact or identify you. Personally identifiable information may encompass, but
                        is not confined to, your email address, name, phone number, and other details ("Personal
                        Information"). We gather this information to deliver the Service, recognize and communicate
                        with you, respond to your requests/inquiries, fulfill your purchase orders, subscriptions,
                        or payments, and enhance our services.
                    </p>

                    <h2>Log Information</h2>
                    <p>We may also compile information that your browser conveys whenever you explore our Service ("Log
                        Data"). This Log Data may encompass details such as your computer's Internet Protocol
                        ("IP") address, browser type, browser version, the pages of our Service that you explore, the
                        time and date of your exploration, the duration spent on those pages, and additional statistics.
                    </p>
                    <p>Moreover, we may utilize third-party services like Google Analytics that amass, supervise,
                        and evaluate this type of information to augment our Service's functionality. These
                        third-party service providers uphold their individual privacy policies governing the utilization of such
                        information.
                    </p>

                    <h2>Browser Cookies</h2>
                    <p>Cookies are small data files comprising an anonymous unique identifier.
                        These cookies are dispatched to your browser from a website and transferred to your device. We leverage
                        cookies to accumulate information for the enhancement of our services.
                    </p>
                    <p>You have the option to instruct your browser to reject all cookies or to signal when a cookie is being
                        dispatched. Most browsers' Help features furnish information on how to accept, disable, or
                        receive notifications when receiving a new cookie.
                    </p>
                    <p>If you decline cookies, some features of our Service may be inaccessible,
                        and we recommend keeping them enabled.
                    </p>

                    <h2>Do Not Track Notification</h2>

                    <p>We support Do Not Track ("DNT"). Do Not Track is a preference that can be configured in your web
                        browser to inform websites that you prefer not to be tracked.
                    </p>
                    <p>You can enable or disable Do Not Track by visiting the Preferences or Settings page of your
                        web browser.
                    </p>

                    <h2>Service Providers</h2>
                    <p>We may enlist third-party entities and individuals to facilitate our Service, deliver the
                        Service on our behalf, conduct Service-associated services, and/or support us in scrutinizing how
                        our Service is utilized.
                    </p>
                    <p>These third parties have access to your Personal Information exclusively to perform specified tasks
                        on our behalf and are obligated not to disclose or employ your information for any other
                        intent.
                    </p>

                    <h2>Correspondence</h2>
                    <p>We may seek your consent to utilize your Personal Information to communicate with you via
                        newsletters, marketing or promotional content, and other information that may be of
                        interest to you. You can opt out of receiving any or all of these communications from us by
                        following the unsubscribe link or instructions provided in any email we send or by
                        contacting us.
                    </p>

                    <h2>Adherence To Legislation</h2>
                    <p>We will reveal your Personal Information as mandated by law, subpoena, or if we believe
                        that such action is essential to comply with the law and the reasonable requests of law
                        enforcement or to safeguard the security or integrity of our Service.
                    </p>

                    <h2>Security Measures</h2>
                    <p>The safeguarding of your Personal Information is a priority for us, and we endeavor to implement and
                        maintain sensible, commercially acceptable security procedures and practices suited
                        to the nature of the information we store to guard it against unauthorized access,
                        destruction, use, modification, or disclosure.
                    </p>
                    <p>The connection between the user and the Service is secured with 128-bit TLS 1.2 encryption
                        (HTTPS protocol). Data is stored using our cloud services and platforms.
                    </p>
                    <p>However, please be mindful that no method of transmission over the internet or electronic
                        storage is entirely secure, and we cannot guarantee the absolute security of the Personal
                        Information we have obtained from you.
                    </p>
                    <p>If the information under our control is compromised due to a security breach, we will take
                        all reasonable actions to investigate the situation, notify affected individuals, and
                        address the issue in accordance with applicable laws and regulations.
                    </p>

                    <h2>Global Data Transfer</h2>
                    <p>Your information, comprising Personal Information, may be transferred and maintained on
                        computers situated outside your state, province, country, or other governmental
                        jurisdiction where data protection laws may contrast from those in your jurisdiction.
                    </p>
                    <p>If you are situated outside the Republic of Tanzania and provide information to us,
                        please be aware that we transfer the information, including Personal Information, to the
                        Republic of Tanzania and the European Union and process it there.
                    </p>
                    <p>Your agreement to this Privacy Policy, accompanied by your submission of such information,
                        signifies your consent to that transfer.
                    </p>
                    <p>The transfer of information to other countries complies with the level of protection
                        mandated by laws or legal recommendations in the Republic of Tanzania and the EU's General
                        Data Protection Regulation (GDPR) effective from 25.05.2018.
                    </p>

                    <h2>Links To External Platforms</h2>
                    <p>Our Service may encompass links to external platforms not managed by us. Clicking on a
                        third-party link will direct you to the respective third party's platform. We strongly urge you to
                        scrutinize the Privacy Policy of each platform you visit.
                    </p>
                    <p>We lack control over and assume no accountability for the content, privacy policies, or
                        practices of any third-party platforms or services.
                    </p>

                    <h2>Privacy Of Minors</h2>
                    <p>Access to our Service is restricted to individuals aged 18 or above. Our Service does not
                        cater to individuals under the age of 16 ("Children").
                    </p>
                    <p>We do not knowingly accumulate personally identifiable information from individuals under the age
                        of 16. If you are a parent or guardian and become aware that your Children have provided us with
                        Personal Information, kindly contact us. If we ascertain that we have gathered Personal
                        Information from individuals under the age of 16 without confirming parental consent, we
                        will undertake measures to eliminate that information from our servers.
                    </p>

                    <h2>Fault-Free Operation</h2>
                    <p>We do not guarantee flawless operation within the confines of this Privacy Policy. We may
                        not invariably detect an inadvertent privacy issue, notwithstanding our efforts to do so. We appreciate your
                        feedback to enhance this Privacy Policy. We will exert reasonable efforts to
                        adhere to this Privacy Policy and will take swift corrective action upon identifying any
                        lapses.
                    </p>

                    <h2>Juridical Information</h2>
                    <p>This Privacy Policy is subject to the stipulations of our Terms of Service Agreement.
                        For a comprehensive overview, visit <a href="http://infcam.net/terms-of-use/">terms of use</a>.
                        It also comprises provisions pertaining to constraints on damages, disclaimers of warranties,
                        and mechanisms for resolving disputes. Our Terms of Service Agreement takes precedence over any
                        conflicting provisions of this Privacy Policy.
                    </p>

                    <h2>Modifications To This Privacy Policy</h2>
                    <p>This Privacy Policy took effect on May 25, 2018, and will remain effective
                        except in the case of any alterations to its provisions in the future, which will be effective
                        immediately upon publication on this page.
                    </p>
                    <p>We preserve the right to update or modify our Privacy Policy at any time, and it is advisable
                        to periodically review this Privacy Policy. Your continued use of the Service following any
                        amendments to the Privacy Policy on this page will indicate your acknowledgment of the
                        amendments and your consent to comply with the revised Privacy Policy.
                    </p>
                    <p>If any substantial changes are made to this Privacy Policy, we will notify you either through
                        the email address you provided us or by prominently featuring a notice on our website.
                    </p>

                    <h2>Contact Us</h2>
                    <p>For any inquiries regarding this Privacy Policy, please do not hesitate to reach out to us:  <a href="mailto:support@infcam.net">support@infcam.net</a></p>

                </div>
            </div>

        </>
    )
}

export default PrivacyPolicyPage;