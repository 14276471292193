import { ReactComponent as ReactLogoIcon } from '../assets/images/flogo.svg'

const FooterComponent = () => {

    return (
        <footer id="footer" className="section-bg">
            <div className="footer-top">
                <div className="container">
                    <div className="row row-cols-1 row-cols-lg-3 gx-lg-5">
                        <div className="col">
                            <div className="footer-info">
                                <ReactLogoIcon/>
                            </div>
                        </div>

                        <div className="col">
                            <div className="footer-links">
                                <h4>Links</h4>
                                <ul>
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/terms-of-use">Terms of service</a></li>
                                    <li><a href="/privacy-policy">Privacy policy</a></li>
                                </ul>
                            </div>
                        </div>

                        <div className="col">
                            <div className="footer-links">
                                <h4>Contact Us</h4>
                                <p className="mb-1">
                                    <strong>Street address</strong>
                                </p>
                                <p className="mb-0">
                                    NEW MUTHAIGA SHOPPING MALL THIGIRI RIDGE ROAD
                                    NAIROBI, 00606
                                </p>
                                <p className="mb-2">
                                    Kenya
                                </p>
                                <p>
                                    <strong>Email:</strong> <a href="mailto:support@infcam.io">support@infcam.io</a>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="container">
                <div className="copyright">
                    &copy; Copyright <strong>InfСam</strong>. All Rights Reserved
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;