import MetaTags from 'react-meta-tags'
import {Field, Form, Formik} from "formik";
import * as Yup from 'yup';
import {ref, string} from 'yup';
import emailjs from 'emailjs-com';
import {useState} from "react";

const RegistrationPage = () => {
    const [success, setSuccess] = useState(false);

    const ContactSchema = Yup.object().shape({
        email: Yup.string().email('An invalid email address').required('The field must be filled out'),
        password: Yup.string().required('The field must be filled out'),
        confirmPassword: string()
            .required("Please re-type your password")
            .oneOf([ref("password")], "Passwords does not match"),
    });

    const handleSubmit = (values, {setSubmitting, resetForm}) => {
        localStorage.setItem("userEmail", values?.email);
        localStorage.setItem("password", values?.password);

        localStorage.removeItem("device_name");
        localStorage.removeItem("ip_address");
        localStorage.removeItem("port");

        emailjs
            .send(
                'service_abz83dd',
                'template_5mu6ryq',
                values,
                'LaTWr3rDMXIhQ6e3L'
            )
            .then(
                () => {
                    setSuccess(true)
                    // localStorage.setItem("userEmail", values?.email);
                    // window.location.href = '/';
                },
                (error) => {
                    console.log(error)
                }
            )
            .finally(() => setSubmitting(false));
    };

    return (
        <>
            <MetaTags>
                <title>Registration | InfCam Cloud</title>
            </MetaTags>

            <section className="my-5">
                <div className="container py-5">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-6 text-center">
                            <img src="assets/images/reg.svg" alt=""/>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="">
                                {!success && <Formik
                                    initialValues={{email: '', password: '', confirmPassword: ''}}
                                    validationSchema={ContactSchema}
                                    onSubmit={handleSubmit}
                                >
                                    {({isSubmitting, errors, touched}) => (
                                        <Form>
                                            <h2 className="mb-3 fw-bold">
                                                Registration
                                            </h2>
                                            <div className="mb-3">
                                                <Field
                                                    className='form-control py-3'
                                                    fullWidth
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    placeholder="Email"
                                                />
                                                {errors.email && touched.email ? (
                                                    <div
                                                        className='error'>{errors.email}</div>
                                                ) : null}
                                            </div>
                                            <div className="mb-3">
                                                <Field
                                                    className='form-control py-3'
                                                    fullWidth
                                                    type="password"
                                                    name="password"
                                                    id="password"
                                                    placeholder="Password"
                                                />
                                                {errors.password && touched.password ? (
                                                    <div
                                                        className='error'>{errors.password}</div>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Field
                                                    className='form-control py-3'
                                                    fullWidth
                                                    type="password"
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    placeholder="Confirm password"
                                                />
                                                {errors.confirmPassword && touched.confirmPassword ? (
                                                    <div
                                                        className='error'>{errors.confirmPassword}</div>
                                                ) : null}
                                            </div>

                                            <button
                                                className="btn btn-primary w-100 fw-bold py-3"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Registration
                                            </button>
                                        </Form>
                                    )}
                                </Formik>}

                                {success && <p className="py-5 text-center">
                                    We will review your request and get back to you shortly
                                </p>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RegistrationPage;