import MetaTags from "react-meta-tags";

const TermsOfUsePage = () => {
    return (
        <>
            <MetaTags>
                <title>Terms of Use | InfCam Cloud</title>
            </MetaTags>

            <div className="not-home">
            <div className="container mx-auto">
                <h1>Terms of Service</h1>

                <h2>General Provisions</h2>
                <p>The software utilized within the Service may automatically download and install updates provided by InfCam onto your devices. These updates may include bug fixes, altered functions, updated software modules, or entirely new versions. By using the Service, you agree to receive and permit the installation of such updates on your devices.</p>

                <h2>Purchases</h2>
                <p>If you intend to purchase any product or service available through the Service ("Purchase"), you may need to provide certain information relevant to your Purchase, including, but not limited to, your credit card number, credit card expiration date, billing address, and shipping information.</p>
                <p>We reserve the right to refuse or cancel your order at any time, including instances such as product or service unavailability, errors in product or service description or pricing, errors in your order, or other reasons.</p>
                <p>You confirm that you have the legal right to use any credit card(s) or payment method(s) associated with your Purchase and that the information you provide is accurate, correct, and complete.</p>
                <p>The service may use third-party services to facilitate payments. By submitting your information, you grant us the right to provide this information to third parties, subject to our Privacy Policy at <a href="https://infcam.net/privacy-policy/">privacy policy</a>.</p>
                <p>We reserve the right to refuse or cancel your order if fraud or unauthorized or illegal transactions are suspected. For any questions or concerns, please contact us via email at <a href="mailto:support@infcam.net">support@infcam.net</a>.</p>

                <h2>Availability, Errors, and Inaccuracies</h2>
                <p>We regularly update product and service offerings on the Service. However, there may be delays in updating information on the Service and in our advertising on other websites. Information on the Service may contain errors or inaccuracies and may not be complete or current. Products or services may be mispriced, inaccurately described, or unavailable on the Service, and we cannot guarantee the accuracy or completeness of any information on the Service.</p>
                <p>We reserve the right to change or update information and to correct errors, inaccuracies, or omissions at any time without prior notice.</p>

                <h2>Subscriptions</h2>
                <p>Certain parts of the Service are billed on a subscription basis ("Subscriptions"). Billing occurs in advance on a recurring basis ("Billing Cycle"), either monthly or annually, depending on your chosen subscription plan. A valid payment method, such as a credit card or PayPal, is required for Subscription payments. By providing payment information, you authorize InfCam to charge the specified amount to your credit card or PayPal through our payment gateway provider.</p>
                <p>At the end of each Billing Cycle, your Subscription may automatically renew under the same conditions unless canceled by you or InfCam. If automatic billing fails, InfCam will issue an electronic invoice for manual payment.</p>

                <h2>Subject</h2>
                <p>These Terms cover the existing Service and its development, expansion, or reduction. InfCam offers the InfCam Storage Service, enabling you to view, record, and process video and audio data from any premises using InfCam-branded equipment. This Service provides online viewing/listening, recording of data, remote access to equipment and recorded information, and other related services detailed at <a href="http://infcam.net/">www.infcam.net</a>.</p>
                <p>By accessing or using the Service, you agree to be bound by these Terms. If you disagree with any part of the terms, you do not have permission to access the Service. Your access and use of the Service are conditioned upon your acceptance and compliance with these Terms, applying to all visitors, users, and others wishing to access or use the Service.</p>

                <h2>DMCA Notice and Procedure for Copyright Infringement Claims</h2>
                <p>You may submit a notification under the Digital Millennium Copyright Act (DMCA) by providing our Copyright Agent with the following information in writing:</p>
                <ul>
                    <li>- An electronic or physical signature of the person authorized to act on behalf of the copyright owner.</li>
                    <li>- A description of the copyrighted work claimed to be infringed, including the URL of the location where it exists.</li>
                    <li>- Identification of the specific location on the Service where the infringing material is located.</li>
                    <li>- Your address, telephone number, and email address.</li>
                    <li>- A statement that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.</li>
                    <li>- A statement, under penalty of perjury, that the provided information is accurate, and you are the copyright owner or authorized to act on their behalf.</li>
                    <li>Contact our Copyright Agent via email at <a href="mailto:support@infcam.net">support@infcam.net</a>.</li>
                </ul>

                <h2>Free Trial</h2>
                <p>InfCam may, at its sole discretion, offer a Subscription with a free trial for a limited period ("Free Trial").</p>
                <p>Even if you provide billing information during the Free Trial signup, you will not be charged by InfCam until the Free Trial expires. To continue using the paid services or Subscriptions, you must select and activate them after the Free Trial period ends.</p>
                <p>InfCam reserves the right to (i) modify the Free Trial terms and conditions, or (ii) cancel the Free Trial offer at any time without notice. You may need to enter billing information to sign up for the Free Trial.</p>

                <h2>Refunds</h2>
                <ul>
                    <li>Requests for refunds on Subscriptions will be considered by InfCam on a case-by-case basis and granted at the sole discretion of InfCam. For questions or concerns, please contact us via email <a href="mailto:support@infcam.net">support@infcam.net</a>.</li>
                </ul>

                <h2>Intellectual Property</h2>
                <p>The Service and its original content, features, and functionality are and will remain the exclusive property of InfCam and its licensors. The Service is protected by copyright, trademark, and other laws of both the Republic of Tanzania and foreign countries. Our trademarks and trade dress may not be used without the prior written consent of InfCam.</p>

                <h2>Accounts</h2>
                <p>By creating an account, you guarantee that you are above the age of 18 and that the information you provide is accurate, complete, and current. Inaccurate, incomplete, or outdated information may result in the immediate termination of your account. You are responsible for maintaining the confidentiality of your account and password, and for all activities that occur under your account. Notify us immediately of any security breach or unauthorized use of your account. The use of offensive or inappropriate usernames may result in the termination of your account. We reserve the right to refuse service, terminate accounts, or cancel orders at our discretion. For questions or concerns, contact us via email <a href="mailto:support@infcam.net">support@infcam.net</a>.</p>

                <h2>Termination</h2>
                <p>We may terminate or suspend your account and access to the Service immediately, without prior notice or liability, for any reason under our sole discretion, including a breach of the Terms. If you wish to terminate your account, you may discontinue using the Service or contact us via email <a href="mailto:support@infcam.net">support@infcam.net</a>. Provisions of the Terms that should survive termination will remain in effect.</p>

                <h2>Compliance With Laws</h2>
                <p>We will disclose your Personal Information as required by law, subpoena, or if necessary to comply with law enforcement requests or protect the security or integrity of our Service.</p>

                <h2>Fee Changes</h2>
                <p>InfCam may, at its discretion and at any time, modify Subscription fees. Any changes will be effective at the end of the current Billing Cycle. We will provide reasonable prior notice of fee changes, allowing you to terminate your Subscription before the changes take effect. Your continued use of the Service after fee changes indicates agreement with the modified Subscription fee amount.</p>

                <h2>Links To Other Websites</h2>
                <p>Our Service may contain links to third-party websites or services not owned or controlled by InfCam. We are not responsible for the content, privacy policies, or practices of third-party websites or services. It is advisable to read the terms and conditions and privacy policies of any third-party websites or services you visit.</p>

                <h2>Governing Law</h2>
                <p>These Terms are governed by the laws of the Republic of Tanzania, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these Terms does not waive those rights. If any provision is deemed invalid or unenforceable, the remaining provisions will remain in effect. These Terms constitute the entire agreement between us and supersede any prior agreements regarding the Service.</p>

                <h2>Changes</h2>
                <p>We reserve the right to modify or replace these Terms at our discretion. Material revisions will be notified at least 30 days before taking effect. Continuing to access or use our Service after revisions indicates acceptance of the revised terms. If you disagree, you are no longer authorized to use the Service.</p>

                <h2>Indemnification</h2>
                <p>You agree to defend, indemnify, and hold harmless InfCam and its licensees, licensors, employees, contractors, agents, officers, and directors from any claims, damages, obligations, losses, liabilities, costs, or expenses resulting from your use of the Service or a breach of these Terms.</p>

                <h2>Limitation Of Liability</h2>
                <p>InfCam, its directors, employees, partners, agents, suppliers, or affiliates are not liable for indirect, incidental, special, consequential, or punitive damages, including loss of profits, data, use, goodwill, or other intangible losses. This includes damages from third-party conduct on the Service, content obtained from the Service, and unauthorized access or alteration of your transmissions or content. Using the Service confirms that liability for information lies with the person who provided or created the information.</p>

                <h2>Disclaimer</h2>
                <p>Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis, without warranties of any kind. InfCam and its licensors do not warrant that the Service will function uninterrupted, secure, or available at any particular time or location, that errors or defects will be corrected, or that the results of using the Service will meet your requirements.</p>

                <h2>Exclusions</h2>
                <p>Some jurisdictions do not allow the exclusion of certain warranties or limitation of liability for consequential or incidental damages, so the above limitations may not apply to you.</p>

                <h2>Contact Us</h2>
                <p>For inquiries regarding this Privacy Policy, feel free to reach out to us: <a href="mailto:support@infcam.net">support@infcam.net</a></p>
            </div>
            </div>
        </>
    )
}

export default TermsOfUsePage;