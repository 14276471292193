import { ReactComponent as ReactLogoIcon } from '../assets/images/logo.svg'

const NavbarComponent = () => {
    const email = localStorage.getItem("userEmail");

    return (
        <nav className="navbar navbar-expand-lg py-3">
            <div className="container d-flex align-items-center">
                <a className="navbar-brand" href="/">
                    <ReactLogoIcon/>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="/">Home</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="/contact">Contact</a>
                        </li>
                        {email && <li className="nav-item ">
                            <a className="nav-link text-primary" href="/account">Account</a>
                        </li>}

                        {!email &&
                            <li className="nav-item"><a className="nav-link text-primary" href="/registration">Registration</a></li>}
                        {!email && <li className="nav-item"><a className="nav-link text-primary" href="/authorization">Login</a></li>}
                        {email && <li className="nav-item">
                            <a className="nav-link text-primary" href="#"
                               onClick={() => {
                                   localStorage.removeItem("userEmail");
                                   window.location.href = '/';
                               }}
                            >Logout</a>
                        </li>}
                    </ul>

                </div>
            </div>
        </nav>
    );
}

export default NavbarComponent;