import MetaTags from "react-meta-tags";
import {ClockHistory, ExclamationCircle, Fingerprint, People} from 'react-bootstrap-icons';

const HomePage = () => {
    return (
        <>
            <MetaTags>
                <title>InfCam | Innovations in Concealed Video Surveillance</title>
            </MetaTags>

            <section id="hero" className="clearfix">
                <div className="container d-flex h-custom">
                    <div className="row w-100 justify-content-center align-items-center g-lg-5" data-aos="fade-up">
                        <div className="col-lg-6 intro-img  d-flex justify-content-center"
                             data-aos="zoom-out" data-aos-delay="200">
                            <img src="assets/images/intro-img.svg" alt="" className="img-fluid"/>
                        </div>

                        <div className="col-12 col-lg-6 intro-info" data-aos="zoom-in"
                             data-aos-delay="100">
                            <h2 className="mb-3">
                                Innovations in Concealed Video Surveillance
                            </h2>
                            <p>
                                Explore the cutting-edge realm of Obscured Video Monitoring Innovations, where state-of-the-art technologies converge to redefine surveillance. Unveil the future of discreet and effective video surveillance solutions, designed to operate seamlessly in various environments while prioritizing privacy and security. Dive into a world where innovation meets discretion, reshaping the landscape of video monitoring for enhanced efficiency and peace of mind.
                            </p>
                            <div className="text-center text-lg-start">
                                <a href="#" className="me-lg-3 mb-3 mb-lg-0 d-block d-lg-inline-block">
                                    <img src="assets/images/apple-tv.svg" alt="Download on the App Store"/>
                                </a>
                                <a href="#">
                                    <img src="assets/images/google.svg"
                                         alt="Get it on Google Play"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <main id="main">
                <section className="aboutNew about bg-white">
                    <div className="container" data-aos="fade-up">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6">
                                <div className="about-content" data-aos="fade-left" data-aos-delay="100">
                                    <h2>Limitless Connectivity</h2>

                                    <p>
                                        InfCam Cloud redefines the recording process, turning it into a seamless
                                        experience from any location. Effortlessly capture video from your InfCam
                                        cameras, as the synergy of you, the camera, and InfCam Cloud forms the
                                        ideal trio for uncomplicated recording.
                                    </p>
                                    <p className="mb-0">
                                        Seize control of your recording settings-choose your preferred method of video
                                        capture, broadcasting, or leveraging additional features provided by our
                                        service. With InfCam, recording is tailored to your preferences and can be
                                        accessed from anywhere.
                                    </p>
                                </div>
                            </div>
                            <div className="col-12 col-lg-6 text-center">
                                <img src="assets/images/about-img.svg" alt=""/>
                            </div>

                        </div>
                    </div>
                </section>


                <section id="services" className="services section-bg">
                    <div className="container" data-aos="fade-up">

                        <header className="section-header">
                            <h3>
                                 Streamlined Video Surveillance
                            </h3>
                            <p>
                                Embrace simplicity and efficiency with our comprehensive cloud storage solution, designed to revolutionize video surveillance. Our intuitive app serves as a centralized platform, eliminating the need for multiple devices or intricate configurations. Seamlessly manage your surveillance needs, regardless of your equipment brand, and experience the unparalleled convenience of our unified platform.
                            </p>
                        </header>

                        <div className="row g-5">

                            <div className="col-md-6 col-lg-7" data-aos="zoom-in" data-aos-delay="300">
                                <div className="box">
                                    <div className="icon">
                                        <ExclamationCircle/>
                                    </div>
                                    <h4 className="title">
                                        Stay Informed with Instant Notifications
                                    </h4>
                                    <p className="description">
                                        Receive real-time notifications for critical events and stay ahead of the curve with InfCam Cloud's proactive alert system. Our timely alerts ensure you're always in the loop, empowering you to take immediate action when necessary.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-5" data-aos="zoom-in" data-aos-delay="200">
                                <div className="box">
                                    <div className="icon">
                                        <People/>
                                    </div>
                                    <h4 className="title">
                                        Collaborate Effortlessly
                                    </h4>
                                    <p className="description">
                                        InfCam Cloud fosters seamless collaboration by enabling effortless video sharing among team members. Share important footage directly with colleagues, providing real-time visibility and ensuring everyone stays on the same page.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-5 wow bounceInUp" data-aos="zoom-in" data-aos-delay="100">
                                <div className="box">
                                    <div className="icon">
                                        <Fingerprint/>
                                    </div>
                                    <h4 className="title">
                                        Maintain Control and Security
                                    </h4>
                                    <p className="description">
                                        Exert complete control over your cloud storage with InfCam Cloud's robust security features. Granular access permissions, secure encryption, and comprehensive audit trails safeguard your sensitive data, granting you peace of mind.
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-6 col-lg-7 wow" data-aos="zoom-in" data-aos-delay="100">
                                <div className="box">
                                    <div className="icon">
                                        <ClockHistory/>
                                    </div>
                                    <h4 className="title">
                                        Enjoy Long-Term Assurance
                                    </h4>
                                    <p className="description">
                                        Benefit from InfCam Cloud's industry-leading two-year extended assurance program. This program extends our standard warranty, providing you with long-term peace of mind and assurance that your cloud storage solution is always backed by our commitment to excellence.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>

                <section className="aboutNew about bg-white">

                    <div className="container" data-aos="fade-up">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <img src="assets/images/introducing.svg" className="w-100" alt=""/>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="about-content" data-aos="fade-left" data-aos-delay="100">
                                    <h2>A New Era of Cloud Storage</h2>
                                    <p>
                                        InfCam Cloud Storage Edge emerges as a frontrunner in the cloud storage landscape, distinguished by its unwavering commitment to simplicity and user-friendliness. Our technology sets the standard for effortless navigation, ensuring intuitive management and crystal-clear accessibility.
                                    </p>
                                    <p className="mb-0">
                                        In a competitive market saturated with complex solutions, InfCam Cloud stands out as a beacon of clarity and ease of use. We've meticulously crafted our platform to empower users of all technical backgrounds, empowering them to seamlessly manage their data without the need for extensive technical expertise.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="aboutNew about section-bg2">
                    <div className="container" data-aos="fade-up">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-9 mx-auto">
                                <img src="assets/images/app.webp" className="w-100" alt=""/>
                                <div className="about-content" data-aos="fade-left" data-aos-delay="100">
                                    <h2>Uninterrupted Recording with InfCam Cloud</h2>

                                    <p>
                                        In the realm of InfCam Cloud Storage, time flows seamlessly, mirroring our uninterrupted recordings. Immerse yourself in the perpetual stream of life, as we deliver round-the-clock coverage, guaranteeing you won't overlook pivotal moments.
                                    </p>

                                    <p>Empowered by cutting-edge motion detection technology, our system flags significant events, granting effortless retrieval of archived videos.</p>

                                    <p>
                                        With InfCam Cloud, you'll never miss a beat. Our unwavering commitment to continuous recording ensures that you have a comprehensive record of events, providing peace of mind and invaluable insights.
                                    </p>

                                    <div className="text-center text-lg-start">
                                        <a href="#" className="me-lg-3 mb-3 mb-lg-0 d-block d-lg-inline-block">
                                            <img src="assets/images/apple-tv.svg" alt="Download on the App Store"/>
                                        </a>
                                        <a href="#">
                                            <img src="assets/images/google.svg"
                                                 alt="Get it on Google Play"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

        </>
    )
}

export default HomePage;