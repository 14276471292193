import MetaTags from 'react-meta-tags'

const ContactsPage = () => {
    return (
        <>
            <MetaTags>
                <title>Contact | InfCam Cloud</title>
            </MetaTags>

            <section className="aboutNew about bg-white">
                <div className="container" data-aos="fade-up">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-4">
                            <div className="about-content" data-aos="fade-left" data-aos-delay="100">
                                <h1 className="fw-bold">Contact</h1>
                                <p className="mb-1">
                                    <strong>Street address</strong>
                                </p>
                                <p className="mb-0">
                                    NEW MUTHAIGA SHOPPING MALL THIGIRI RIDGE ROAD
                                    NAIROBI, 00606
                                </p>
                                <p className="mb-2">
                                    Kenya
                                </p>
                                <p className="mb-0">
                                    <strong>Email:</strong>
                                </p>
                                <p>
                                    <a href="mailto:support@infcam.io">support@infcam.io</a>
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-8 text-center">
                            <img src="assets/images/contact.svg" alt=""/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactsPage;